<template lang="pug">
  v-tabs(v-model="activeTab" background-color="transparent").choose-course-tabs
    v-tab(
      v-for="tab in tabs"
      :key="tab.name"
      @click="switchTab(tab.name)"
    )
      div
        div {{ tab.label }}
</template>

<script>
import { COURSE_DAY_SIDEBAR_TABS } from '../../core/courseDay-const'

export default {
  name: 'ChooseCourseTabs',

  props: {
    activeTabName: String
  },

  data: () => ({
    activeTab: 0,
    COURSE_DAY_SIDEBAR_TABS
  }),

  computed: {
    tabs() {
      return [
        {
          name: COURSE_DAY_SIDEBAR_TABS.FUTURE,
          label: 'Future'
        },
        {
          name: COURSE_DAY_SIDEBAR_TABS.PAST,
          label: 'Past'
        },
      ]
    }
  },

  methods: {
    async switchTab(name) {
      let tabIndex = this.tabs.findIndex(tab => tab.name === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
      this.$emit('click:tab', name)
    }
  },
  watch: {
    activeTabName: {
      handler: function () {
        let tabIndex = this.tabs.findIndex(tab => tab.name === this.activeTabName)
        if (tabIndex < 0) return
        this.activeTab = tabIndex
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.choose-course-tabs {
  .v-slide-group__prev, .v-slide-group__next {
    display: none !important;
  }

  .v-tabs-bar {
    height: 35px;
  }

  .v-tab {
    width: 100%;
  }
}
</style>
